import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Breadcrumb,
  Button,
  Form,
  Header,
  Menu,
  Modal,
} from "semantic-ui-react";
import { connect } from "react-redux";
import WebCam from "./WebCam";
import {
  addSteps,
  editStepDetails,
  updateStep,
  editStepDetailsReset,
} from "../../redux/actions";
import WebCamVideo from "./WebCamVideoModal";
import { isMobile } from "react-device-detect";
import { saveNewStep, saveEditedStep, } from "../apiCall";

const NewStep = (props) => {
  const sections = [
    { key: "Fault title", content: "Fault title", link: false },
    { key: "Add steps", content: "Add steps", link: false },
    { key: "New step", content: "New step", active: true },
  ];

  const [stepForm, setStepsForm] = useState({
    title: "",
    description: "",
    tools: "",
    spares: "",
    additionalInfo:"",
    documents:"",
    uploadedMedia: "",
    capturedMedia: "",
    deleteFileCollection: "",
  });
  const [fileDetails, setFileDetails] = useState(props.editStep[0]?.uploadedMedia ? props.editStep[0].uploadedMedia : []);
  const [capturedFileDetails, setCapturedFileDetails] = useState(props.editStep[0]?.capturedMedia ? props.editStep[0].capturedMedia : []);
  const [enableWebCam, setEnableWebCam] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openImageVideoModal, setOpenImageVideoModal] = useState(false);
  const [enableWebCamVideo, setEnableWebCamVideo] = useState(false);
  const [deleteFileCollection, setDeleteFileCollection] = useState([]);
  const [mediaFilesDownloaded, setMediaFilesDownloaded] = useState(
    props.editStep[0] ? props.editStep[0].media : null
  );
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [showPendingAlert, setShowPendingAlert] = useState(false);
  const fileRef = useRef();

  const handleClose = () => {
    setEnableWebCam(false);
    setEnableWebCamVideo(false);
  };

  useEffect(() => {
    if (props.editStep.length > 0) {
      const stepDetails = {
        title: props.editStep[0].title,
        description: props.editStep[0].description,
        tools: props.editStep[0].tools,
        spares: props.editStep[0].spares,
        additionalInfo: props.editStep[0].additionalInfo,
        documents: props.editStep[0].documents,
        uploadedMedia: props.editStep[0].uploadedMedia,
        capturedMedia: props.editStep[0].capturedMedia,
        deleteFileCollection: props.editStep[0].deleteFileCollection,
      };
      setStepsForm(stepDetails);
    }
    var isIOS = /iPad|iPhone|iPod|Mac/.test(navigator.userAgent);
    setIsMobileDevice((isIOS && isMobile) || isMobile);
  }, [props.editStep]);

  const handleChange = (e) => {
    setStepsForm({ ...stepForm, [e.target.name]: e.target.value });
  };

  /* breadcrum fault title */
  sections[0].content = props.title;

  /* Function to take the user to new step page */
  const getNewSolution = async() => {
    setShowPendingAlert(true);
    if (props.editStep.length === 0) {
      //save new step details
      await saveNewStepDetails();
    } else {
      //update step details
      await updateStepDetails();
    }
    setShowPendingAlert(false);
    setShowSaveAlert(true);
    setTimeout(() => {
      props.history.goBack();
    }, 1000);
    
  };

  // Update step details
  const updateStepDetails = async() => {
    var data = {
      title: stepForm.title,
      description: stepForm.description,
      tools: stepForm.tools,
      spares: stepForm.spares,
      additionalInfo: stepForm.additionalInfo,
      documents:stepForm.documents,
      order_id: props.editStep[0].order_id,
      stepId: props.editStep[0].stepId,
      uploadedMedia: fileDetails,
      capturedMedia: capturedFileDetails,
      deleteFileCollection: deleteFileCollection,
    };
    console.log('sdata', data);
    //Update the existing step with edited values
    const formData = createFormData(data, true);
    await saveEditedStep(props.solutionId, formData,props.editStep[0].stepId ).then((response) => {
      data.media = response.data.data.media;
      data.capturedMedia = [];
      data.uploadedMedia = [];
    });
    props.updateStep(data);
    props.editStepDetailsReset(true);
  }

  //Save new step details
  const saveNewStepDetails = async() => {
    if (stepForm.title.length > 0) {
      var data = {
        title: stepForm.title,
        description: stepForm.description,
        tools: stepForm.tools,
        spares: stepForm.spares,
        additionalInfo:stepForm.additionalInfo,
        documents:stepForm.documents,
        order_id: props.steps.length,
        stepId: "",
        uploadedMedia: fileDetails,
        capturedMedia: capturedFileDetails,
      };
      const formData = createFormData(data);
        await saveNewStep(formData, props.solutionId).then((response) => {
        const result = response.data.data.steps;
        data.stepId = result.stepId;
        data.media = result.media;
        data.capturedMedia = [];
        data.uploadedMedia = [];
      });
      props.addSteps(data);
    }
  }

  // Create formdata to save step
  const createFormData = (step, isEditstep = false) => {
    const formData = new FormData();
    const uploadedMedia = step.uploadedMedia;
    const capturedMedia = step.capturedMedia;
    let stepDetail;
    if (isEditstep) {
      stepDetail = {
        title: step.title,
        description: step.description,
        tools: step.tools,
        spares: step.spares,
        additionalInfo: step.additionalInfo,
        documents: step.documents,
        order_id: step.order_id,
        _id: step.stepId,
        mediaToBeDeleted: step.deleteFileCollection,
      };
    } else {
      stepDetail = {
        title: step.title,
        description: step.description,
        tools: step.tools,
        spares: step.spares,
        additionalInfo: step.additionalInfo,
        order_id: step.order_id,
        stepId: "",
        documents: step.documents,
      };
    }
    formData.append("data", JSON.stringify(stepDetail));
    if (uploadedMedia && uploadedMedia.length > 0) {
      for (let i = 0; i < uploadedMedia.length; i++) {
        formData.append("media", uploadedMedia[i]);
      }
    }
    if (capturedMedia && capturedMedia.length > 0) {
      for (let i = 0; i < capturedMedia.length; i++) {
        formData.append("media", capturedMedia[i]);
      }
    }
    return formData;
  };

  const validateFields = () => {
    if (
      stepForm.title.length &&
      stepForm.description.length &&
      stepForm.tools.length &&
      stepForm.spares.length &&
      stepForm.additionalInfo.length
    ) {
      return true;
    }
    return false;
  };

  // Set Uploaded files
  const handleInputChange = (e) => {
    e.preventDefault();
    setEnableWebCam(false);
    const fileData = e.target.files;
    for(let i = 0; i<fileData.length ; i++) {
      if(!fileData[i].name){
        const binary = atob(fileData[i].split(",")[1]);
        const array = [];
        var file ;
        for (let i = 0; i < binary.length; i += 1) {
          array.push(binary.charCodeAt(i));
        }
        if(fileData[i].mimetype === "video/quicktime"){
          file = new Blob([new Uint8Array(array)], {
            type: "video/quicktime",
          });
          file.name = `${new Date()}.mp4`;
        }
        else {
          file = new Blob([new Uint8Array(array)], {
          type: "image/png",
        });
        file.name = `${new Date()}.png`;
      }
        file.lastModifiedDate = new Date();
        const myFile = new File([file], file.name,{
          type: file.type,
        });
        setFileDetails((fileDetails)=>[...fileDetails, myFile]);
      }
      setFileDetails((fileDetails)=>[...fileDetails, fileData[i]]);
    }
  };

  // Set captured image
  const getCapturedImage = useCallback((imageFile, imageData) => {
    setCapturedFileDetails((capturedFileDetails) => [
      ...capturedFileDetails,
      imageFile,
    ]);
    setEnableWebCam(false);
  }, []);

  // Open Web camera to capture photo
  const renderWebCam = () => {
    setOpenImageVideoModal(!openImageVideoModal);
    setEnableWebCam(true);
  };

  // Open we camera for capturing video
  const renderWebCamVideo = () => {
    setOpenImageVideoModal(!openImageVideoModal);
    setEnableWebCamVideo(true);
  };

  // open video capture modal
  const renderImageVideoModal = () => {
    setIsModalOpen(!isModalOpen);
    setOpenImageVideoModal(true);
  };

  const renderUploadFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsModalOpen(false);
    fileRef.current.click();
  };

   // Render images and videos
  const renderImagesAndVideos = () => {
    var rendreFiles = [];
    // Uploaded files
    if (fileDetails?.length > 0) {
      for (let i = 0; i < fileDetails.length; i++) {
        if (
          fileDetails[i].type === "image/png" ||
          fileDetails[i].type === "image/jpeg" ||
          fileDetails[i].type === "image/jpg"          
        ) {
          rendreFiles.push(
            <div className="image-container">
              <img
                src={URL.createObjectURL(fileDetails[i])}
                className="photo"
                typeof="image/png, image/jpeg, image/jpg, image/*, JPEG/H.264"
                alt=""
              />
              <button className="close-button-position"
                onClick={() => removeFile(fileDetails[i].name, "fileDetails")}
              >
                X
              </button>
            </div>
          );
        } else if (fileDetails[i].type === "video/mp4" ||
                   fileDetails[i].type === "MOV" ||
                   fileDetails[i].type === "mov" ||
                   fileDetails[i].type === "video/quicktime" ||
                   fileDetails[i].type === "video/*" ||
                   fileDetails[i].type === "HEVC/H.264" ||
                   fileDetails[i].type === "hevc/h.264") {
          rendreFiles.push(
            <div className="image-container">
              <video preload="metadata" className="photo">
                <source
                  className="photo"
                  src={URL.createObjectURL(fileDetails[i])}
                  alt=""
                />
              </video>
              <button className="close-button-position"
                onClick={() => removeFile(fileDetails[i].name, "fileDetails")}
              >
                X
              </button>
            </div>
          );
        }
      }
    }
    // Captured files
    if (capturedFileDetails?.length > 0) {
      for (let i = 0; i < capturedFileDetails.length; i++) {
        if (capturedFileDetails[i].type === "video/mp4" || 
            capturedFileDetails[i].type === "HEVC/H.264" ||
            capturedFileDetails[i].type === "hevc/h.264" ||
            capturedFileDetails[i].type === "video/*" ||
            capturedFileDetails[i].type === "video/quicktime" ||
            capturedFileDetails[i].type === "mov" ||
            capturedFileDetails[i].type === "MOV"
            ) {
          rendreFiles.push(
            <div className="image-container">
              <video className="photo" controls>
                <source
                  className="photo"
                  src={URL.createObjectURL(capturedFileDetails[i])}
                  alt=""
                />
              </video>
              <button className="close-button-position"
                onClick={() =>
                  removeFile(capturedFileDetails[i].name, "capturedFileDetails")
                }
              >
                X
              </button>
            </div>
          );
        } else if (capturedFileDetails[i].type === "image/png") {
          rendreFiles.push(
            <div className="image-container">
              <img
                src={URL.createObjectURL(capturedFileDetails[i])}
                className="photo"
                typeof="image/png, image/jpeg, image/jpg"
                alt=""
              />
              <button className="close-button-position"
                onClick={() =>
                  removeFile(capturedFileDetails[i].name, "capturedFileDetails")
                }
              >
                X
              </button>
            </div>
          );
        }
      }
    }
    // Downloaded files
    if (mediaFilesDownloaded) {
      mediaFilesDownloaded.forEach((media) => {
        if (
          media.extention === "png" ||
          media.extention === "PNG" ||
          media.extention === "jpeg" ||
          media.extention === "JPEG" ||
          media.extention === "jpg" ||
          media.extention === "JPG"
        ) {
          rendreFiles.push(
            <div className="image-container">
              <img
                className="photo"
                src={media.url}
                typeof="image/png, image/jpeg, image/jpg"
                alt=""
              />
              <button className="close-button-position" onClick={() => removeFile(media.fileName, "media")}>
                X
              </button>
            </div>
          );
        }
        if (media.extention === "mp4" ||
            media.extention === "MOV" ||
            media.extention === "mov") {
          rendreFiles.push(
            <div className="image-container">
              <video className="photo" controls>
                <source
                  className="photo"
                  src={media.url}
                  alt=""
                  type="video/mp4, MOV, mov"
                />
              </video>
              <button className="close-button-position" onClick={() => removeFile(media.fileName, "media")}>
                X
              </button>
            </div>
          );
        }
      });
    }
    return rendreFiles;
  };

  // Remove files
  const removeFile = (fileName, collectionName) => {
    const fileUpdatedCollection = [];
    switch (collectionName) {
      case "fileDetails":
        if (fileDetails.length > 0) {
          for (let i = 0; i < fileDetails.length; i++) {
            if (fileDetails[i].name !== fileName) {
              fileUpdatedCollection.push(fileDetails[i]);
            }
          }
          setFileDetails(fileUpdatedCollection);
        }
        break;
      case "capturedFileDetails":
        if (capturedFileDetails.length > 0) {
          for (let i = 0; i < capturedFileDetails.length; i++) {
            if (capturedFileDetails[i].name !== fileName) {
              fileUpdatedCollection.push(capturedFileDetails[i]);
            }
          }
          setCapturedFileDetails(fileUpdatedCollection);
        }
        break;
      case "media":
        if (mediaFilesDownloaded) {
          mediaFilesDownloaded.forEach((media) => {
            if (media.fileName !== fileName) {
              fileUpdatedCollection.push(media);
            } else {
              setDeleteFileCollection((deleteFileCollection) => [
                ...deleteFileCollection,
                media.fileName,
              ]);
            }
          });
          setMediaFilesDownloaded(fileUpdatedCollection);
        }
        break;
      default: break;

    }
  };

  // Get recorded Video
  const getRecordedVideo = useCallback((videoFile) => {
    setCapturedFileDetails((capturedFileDetails) => [
      ...capturedFileDetails,
      videoFile,
    ]);
    setEnableWebCamVideo(false);
    setEnableWebCam(false);
  }, []);

  const onClickBackButton = () => {
    props.editStepDetailsReset(true);
    props.history.goBack();
  };

  let submitAlertClass = showSaveAlert ? "modal-alert animate" : "modal-alert";
  let submitAlertStyles = showSaveAlert || showPendingAlert
    ? {}
    : { opacity: 0, visibility: "hidden", pointerEvents: "none" };


  return (
    <>
      {enableWebCam && (
        <WebCam
          getCapturedImage={getCapturedImage}
          showModal={enableWebCam}
          handleClose={handleClose}
        />
      )}
      {enableWebCamVideo && (
        <WebCamVideo
          getRecordedVideo={getRecordedVideo}
          showModal={enableWebCamVideo}
          handleClose={handleClose}
        />
      )}
      <div className="session-summary">
      {showSaveAlert && <div
        className={submitAlertClass}
        style={submitAlertStyles}
        variant={"success"}
      >
        <div className="modal-alert-icon">
          <img
            className="PopUpIcon"
            src={require("../../assets/images/Shape 1.png")}
            alt=""
          />
        </div>
        <div className="model-alert-msg">
          <h4 className="Heading">Step Saved</h4>
          <p className="Para">You can edit it again later</p>
        </div>
      </div>}
      {showPendingAlert && <div
        className={submitAlertClass}
        style={submitAlertStyles}
        variant={"warning"}
      >
        <div className="modal-alert-icon">
          <img
            className="PopUpIcon"
            src={require("../../assets/images/pending-shape.png")}
            alt=""
          />
        </div>
        <div className="model-alert-msg">
          <h4 className="Heading">Step saving in progress</h4>
          <p className="Para">Please wait while saving is completed</p>
        </div>
      </div>}
        <div className="main-heading">
          <h2>Add a solution</h2>
        </div>
        <div className="add-solution">
          <Breadcrumb
            className="breadcrumb"
            icon="right angle"
            sections={sections}
          />
        </div>
        <div className="add-solution">
          <Header as="h3">New step</Header>
        </div>

        <Form>
          <Form.Field>
            <p className="asLabel">Step title *</p>
            <input
              name="title"
              placeholder="Step title"
              value={stepForm.title}
              onChange={handleChange}
            />
          </Form.Field>

          <Form.Field>
            <p className="asLabel">What would you do for this step? *</p>
            <textarea
              name="description"
              placeholder="What would you do in this step?"
              rows="5"
              onChange={handleChange}
              value={stepForm.description}
            />
            <p className="mandatory-label">{stepForm?.description?.length} Characters</p>
          </Form.Field>
          <div className="myContainer">
            <div>
              {(fileDetails || capturedFileDetails || props.editStep.length > 0) && (
                <div className="image-wrap">{renderImagesAndVideos()}</div>
              )}
            </div>
            {!isMobileDevice && <div className="take">
              <img
                src={require("../../assets/images/Frame 71.png")}
                alt=""
                onClick={() => setIsModalOpen(true)}
              />
                <input
                  type="file"
                  id="video"
                  className="takefile"
                  name="media"
                  multiple
                  accept="image/png, image/jpeg, image/jpg, video/mp4, image/*, video/*, HEVC/H.265, HEVC/H.264, JPEG/H.264, MOV, mov"
                  onChange={handleInputChange}
                  ref={fileRef}
                />
            </div>}
            {isMobileDevice && <div className="take">
              <img
                src={require("../../assets/images/Frame 71.png")}
                alt=""
                onClick={renderUploadFile}
              />
                <input
                  type="file"
                  id="video"
                  className="takefile"
                  name="media"
                  multiple
                  accept="image/png, image/jpeg, image/jpg, video/mp4, image/*, video/*, HEVC/H.265, HEVC/H.264, JPEG/H.264, MOV, mov"
                  onChange={handleInputChange}
                  ref={fileRef}
                />
              </div>
              } 
          </div>
          <Form.Field>
            <p className="asLabel">Tools required</p>
            <input
              name="tools"
              rows="5"
              placeholder="Tools required"
              value={stepForm.tools}
              onChange={handleChange}
            />
          </Form.Field>

          <Form.Field>
            <p className="asLabel">Necessary spares</p>
            <input
              name="spares"
              rows="5"
              placeholder="Necessary spares"
              onChange={handleChange}
              value={stepForm.spares}
            />
          </Form.Field>
          <Form.Field>
            <p className="asLabel">Additional Information</p>
            <textarea
              name="additionalInfo"
              rows="5"
              placeholder="Additional Information"
              onChange={handleChange}
              value={stepForm.additionalInfo}
            />
            <p className="mandatory-label">{stepForm?.additionalInfo?.length} Characters</p>
          </Form.Field>
          {/* <Form.Field>
            <p className="asLabel">Additional Information *</p>
            <input
              name="additionalInfo"
              rows="5"
              placeholder="Additional Information"
              onChange={handleChange}
              value={stepForm.additionalInfo}
            />
          </Form.Field> */}

          <Form.Field>
            <p className="asLabel">Documents referenced</p>
            <input
              name="documents"
              rows="5"
              placeholder="Documents referenced"
              onChange={handleChange}
              value={stepForm.documents}
            />
          </Form.Field>
        </Form>

        <Menu borderless fixed="bottom" id="menu">
          <Menu.Item position="left">
            <Button className="secondarybtn" onClick={onClickBackButton}>
              Back
            </Button>
          </Menu.Item>
          <Menu.Item position="right">
            <Button
              type="button"
              className="primarybtn"
              onClick={() => {
                getNewSolution();
              }}
              disabled={!validateFields()}
            >
              Save step
            </Button>
          </Menu.Item>
        </Menu>
      </div>
      {isModalOpen && (
        <Modal className="modal-upload" open>
          <div className="modal-action">
            <Modal.Actions style={{ height: "100%" }}>
              <div className="modal-button-option">
                <Button
                  className="button-upload-option"
                  onClick={renderImageVideoModal}
                >
                  Take Photo/Video
                </Button>
              </div>
              <div className="modal-button-option">
                <Button
                  className="button-upload-option"
                  onClick={renderUploadFile}
                >
                  Upload Photo/Video
                </Button>
              </div>
            </Modal.Actions>
          </div>
        </Modal>
      )}
      {openImageVideoModal && (
        <Modal className="modal-upload" open>
          <div className="modal-action">
            <Modal.Actions style={{ height: "100%" }}>
              <div className="modal-button-option">
                <Button className="button-upload-option" onClick={renderWebCam}>
                  Capture Image
                </Button>
              </div>
              <div className="modal-button-option">
                <Button
                  className="button-upload-option"
                  onClick={renderWebCamVideo}
                >
                  {" "}
                  Capture Video
                </Button>
              </div>
            </Modal.Actions>
          </div>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    title: state.solution.title,
    code: state.solution.code,
    type: state.solution.type,
    description: state.solution.description,
    solutionId: state.solution.solutionId,
    steps: state.steps,
    editStep: state.editStep,
  };
};

export default connect(mapStateToProps, {
  addSteps,
  editStepDetails,
  updateStep,
  editStepDetailsReset,
})(NewStep);