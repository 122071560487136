import * as React from "react";
import { Icon, Modal, Button } from "semantic-ui-react";
import Recorder from "recorder-js";
const { v4: uuidv4 } = require("uuid");
const swaggerAPI = "https://vttdev.lexxtechnologies.com/audioTransfer";
/* This component is for the search bar */
class LexxSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "", // search query string
      show: false,
      blob: null,
      isRecording: false,
      stream: null,
      analyserData: { data: [], lineTo: 0 },
      recordingLabel: "Speak Now",
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchClick = this.searchClick.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.download = this.download.bind(this);
    this.getTranscriptText = this.getTranscriptText.bind(this);
    this.handleIconPress = this.handleIconPress.bind(this);

    this.audioContext = new AudioContext({ sampleRate: 16000 });

    this.recorder = new Recorder(this.audioContext, {numChannels:1});

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.setState({ stream });
        this.recorder.init(stream);
      })
      .catch(this.dontGotStream);
  }

  start() {
    this.recorder.start().then(() => this.setState({ isRecording: true }));
  }

  stop() {
    this.recorder
      .stop()
      .then(({ blob }) => {
        this.setState({
          isRecording: false,
          blob,
        });
        this.download();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  dontGotStream(error) {
    console.log("Get stream failed", error);
  }

  download() {
    Recorder.download(this.state.blob, 'Recording');
    console.log(this.state.blob);
    this.getTranscriptText();
  }

  async getTranscriptText() {
    var date = new Date();
    const formData = new FormData();
    formData.append("annotated", true);
    formData.append("audioid", uuidv4());
    formData.append("date", date.toLocaleDateString());
    formData.append("filebin", this.state.blob);
    formData.append("trained", true);
    var request = new XMLHttpRequest();
    request.open("POST", swaggerAPI, true);
    request.send(formData);
    request.onload = () => {
      if (request.status === 201) {
        const data = JSON.parse(request.response);
        if (data.recognized_text) {
          console.log(data.recognized_text);
          this.setState({
            searchString: data.recognized_text,
          });
          setTimeout(() => {
            this.searchClick();
          }, 3000);
        }
      }
    };
  }

  /* Function called 
        When enter key is pressed , search the solr 
    */
  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.props.act(this.state.searchString);
    }
  }

  /* Update the search String when user types */
  handleChange(event) {
    this.setState({
      searchString: event.target.value,
    });
  }

  /* Function called 
        When btn is clicked , search the solr 
    */
  searchClick() {
    this.props.act(this.state.searchString);
  }

  handleIconPress(e) {
    e.preventDefault();
    this.setState({
      show: true,
    });
    setTimeout(() => {
      this.setState({
        recordingLabel: "Listenting...",
      });
    }, 1000);
    this.start();
    setTimeout(() => {
      this.stop();
      this.setState({
        show: false,
      });
    }, 10000);
  }

  render() {
    const { isRecording, blob, stream } = this.state;

    return (
      <div>
        <input
          className="search-input"
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          value={this.state.searchString}
          placeholder="Search"
        />
        <Icon
          name="microphone"
          onClick={(e) => this.handleIconPress(e)}
          className="microphone"
        />
        <Icon name="arrow right" onClick={() => this.searchClick()} />
        {this.state.show && (
          <Modal
            className="modal-upload"
            style={{ top: "50%", width: "100%" }}
            open
          >
            <div className="modal-action-recording">
              <Modal.Content>
                <p className="modal-microphone">
                  {this.state.recordingLabel}
                  <Icon name="microphone" className="iconwarp" />
                </p>
              </Modal.Content>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default LexxSearchBar;