import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Menu, Image, Icon, Button } from 'semantic-ui-react';
import prodLogo from '../../assets/images/lexx-energy-logo.png';
import ContextMenu from './ContextMenu';
import { connect } from 'react-redux';
import { changeSidebarVisibility, unreadSessionMessages, profileCompleted, loginAction } from '../../redux/actions';
import axios from "axios";
import { sendFirebaseToken, authSignup } from '../apiCall';
import firebase from "../../configs/firebase";
import { EventSourcePolyfill } from 'event-source-polyfill';
import KeycloakUserService from '../login/KeyCloakService';
import LexxGeolocation from '../LexxGeolocation/LexxGeolocation';

/* Component is used as a Header in webapp */
const Header = (props) => {

    const [unread, setUnread] = useState(0); //to show the number of unread messages

    const showSidebar = () => {
        // getUnreadCount(); 
        props.changeSidebarVisibility(props.sidebarVisible);
    };

    /* This function converts the UTC date to readable date */
    const getDate = (dateUTC) => {
        return dateUTC.getDate() + '/' + (dateUTC.getMonth() + 1) + '/' + dateUTC.getFullYear();
    }

    /* This function converts the UTC date to readable time */
    const getTime = (dateUTC) => {
        return dateUTC.getHours() + ":" + dateUTC.getMinutes();
    }

    const firstLoginCheck = () => {
        props.loginAction(KeycloakUserService.getEmail(), KeycloakUserService.getToken());
        authSignup().then((response) => {
            if ((getDate(new Date(response.data.data.createTime)) === getDate(new Date(response.data.data.updateTime)))
                && (getTime(new Date(response.data.data.createTime)) === getTime(new Date(response.data.data.updateTime)))) {
                props.profileCompleted(false);
                props.history.push("/editProfile");
            } else {
                props.profileCompleted(true);
                // props.history.push("/");
            }
        }).catch((err) => {
            console.log(err);
        });
        getNotificationUnreadCount();
    }
    /* get the notification unread Count for the user */
    const getNotificationUnreadCount = () => {

        FireBaseNotification(); // Firebase notification to be called
        var EventSource = EventSourcePolyfill;
        const source = new EventSource('http://localhost:9500/notifications/unread/stream', {
            headers: {
                'Authorization': localStorage.getItem("app_token") // or localStorage.getItem("myToken")
            }
        });

        source.onmessage = function logEvents(event) {
            setUnread(JSON.parse(event.data));
        };
    }

    /* This is the function to initalize the firebase Notification system */
    const FireBaseNotification = () => {
        if (firebase.messaging.isSupported()) { //check if firebase messaging is supported
            const messaging = firebase.messaging();
            Notification.requestPermission().then(() => { // request for sending out Notifications
                return messaging.getToken();
            }).then((token) => {
                sendFirebaseToken({ "token": token }).then().catch((error) => { // send Firebase Token to the backend
                    console.log(error);
                });
                messaging.onMessage((payload) => {
                    // Default behavior is only showing notification when app is in background
                    // This enables showing notification when app in FOREGROUND
                    const notificationTitle = payload.notification.title;
                    const notificationOptions = { body: payload.notification.body, icon: "http://static.wixstatic.com/media/d80370_7497cf15149845b59dccce3685746868~mv2.png/v1/fill/w_218,h_100,al_c,q_85,usm_0.66_1.00_0.01/Company%20Logo.webp" };
                    if (!("Notification" in window)) {
                        console.log("This browser does not support system notifications");
                    }
                    else if (Notification.permission === "granted") { // if notification granted
                        var notification = new Notification(notificationTitle, notificationOptions);
                        // This is where the onClick of notification in foreground is defined
                        notification.onclick = function (event) {
                            event.preventDefault(); // prevent the browser from focusing the Notification's tab
                            window.open("/allNotifications", '_blank');
                            notification.close();
                        }
                    }
                });
            }).catch((err) => {
                console.error(err);
            });
        }
    }

    useEffect(firstLoginCheck, []);

    axios.defaults.headers.common['Authorization'] = localStorage.getItem("app_token");

    return (
        <>
            <LexxGeolocation />
            <Menu borderless fixed='top' id="menu">
                {props.isCompleted ?
                    <Menu.Item position='left'>
                        <Button icon basic className='btn menu-icon-btn'
                            onClick={showSidebar}
                        >
                            <Icon name='bars' size='large' />
                        </Button>
                    </Menu.Item>
                    : null}
                <Menu.Item className='prod-logo'>
                    <Image src={prodLogo} className='header-logo-img' onClick={() => props.history.push("/")} />
                </Menu.Item>
                <Menu.Item position='right' className='menu-icon-btn'>
                    {props.isCompleted ?
                        <> <Icon name="bell outline" onClick={() => props.history.push("/allNotifications")} /> {unread > 0 ? <span className="notification-number"> {unread} </span> : null} </>
                        : null}
                    <ContextMenu />
                </Menu.Item>
            </Menu>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        sidebarVisible: state.sidebarVisible,
        isCompleted: state.isCompleted
    }
}

export default withRouter(connect(mapStateToProps, {
    changeSidebarVisibility, unreadSessionMessages, loginAction, profileCompleted
})(Header));