import React, { useState, useEffect } from "react";
import {
  Table,
  Label,
  Menu,
  Button,
  Input,
  Form,
  Dropdown,
  Grid,
} from "semantic-ui-react";
import { colorName } from "./colorname";
import {
  deleteSolution,
  getAllSolutions,
  getSolutionById,
  getStepBySolutionId,
} from "../apiCall";
import {
  resetSolutionFault,
  resetSteps,
  addSolution,
  addSteps,
  addStepCollection,
  solutionNotSaved,
} from "../../redux/actions";
import { connect } from "react-redux";

const NewSolutions = (props) => {
  const solution = [
    {
      solutionId: "",
      faultTitle: "",
      createdBy: "",
      solutionStatus: "",
    },
  ];
  const [solutions, setSolutions] = useState(solution);
  const [solutionsByStatus, setSolutionsByStatus] = useState(solutions);
  /* Function to take the user to add fault details page */
  const getAddFaultDetails = () => {
    props.history.push(`/addsolutionsfaultdetails/`);
  };
  /* This functions gets all the solutions from the db */
  const getSolutions = () => {
    getAllSolutions()
      .then((response) => {
        response.data.data.solutions.forEach((data) => {
          if (data.solutionStatus === statusOptions[0].value) {
            data.solutionStatus = statusOptions[0].key;
          }
          if (data.solutionStatus === statusOptions[1].value) {
            data.solutionStatus = statusOptions[1].key;
          }
          if (data.solutionStatus === statusOptions[2].value) {
            data.solutionStatus = statusOptions[2].key;
          }
        });
        setSolutions(response.data.data.solutions);
        setSolutionsByStatus(response.data.data.solutions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const solutionId = props.solutionId;
    if(props.isSolutionNotSaved && solutionId) {
      deleteSolution(solutionId).then((response)=>{
        console.log(response);
      });
    }
    getSolutions();
    props.resetSolutionFault(true);
    props.resetSteps(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const deleteIconStatus = (data) => {
    // if (data.solutionStatus === "IN REVIEW") {
      return <i className="trash alternate icon disabled" id="penIcon"></i>;
    // } else {
    //   return (
    //     <i
    //       className="trash alternate icon"
    //       id="penIcon"
    //       onClick={() => handleDelete(data.solutionId)}
    //     ></i>
    //   );
    // }
  };

  const handleDelete = async(id) => {
    await deleteSolution(id);
    getSolutions();
  };

  const handleEditSolution = async(data) => {
    await getSolutionById(data.solutionId).then((response) => {
      var faultDetails = {
        title: response.data.data.solution.faultTitle,
        code: response.data.data.solution.faultCode,
        type: response.data.data.solution.type,
        description: response.data.data.solution.faultDescription,
        solutionId: data.solutionId,
      };
      props.addSolution(faultDetails);
    });
    await getStepBySolutionId(data.solutionId).then((response) => {
      response.data.data.steps.forEach((step) => {
        var data = {
          title: step.stepTitle,
          description: step.stepDescription,
          tools: step.tools,
          spares: step.spares,
          additionalInfo: step.additionalInfo,
          documents: step.documents,
          order_id: step.order_id,
          stepId: step.stepId,
          media:step.media
        };
        props.addSteps(data);
        props.addStepCollection(data);
      });
    });
    setTimeout(() => {
      props.history.push(`/addsolutionssteps`);
    }, 1000);
  };

  const editIconStatus = (data) => {
    // if (data.solutionStatus === "IN REVIEW") {
    //   return <i className="pencil alternate icon disabled" id="penIcon"></i>;
    // } else {
      return (
        <i
          className="pencil alternate icon"
          id="penIcon"
          onClick={() => handleEditSolution(data)}
        ></i>
      );
    // }
  };

  const displayData = solutionsByStatus.map((info) => {
    return (
      info.faultTitle.length > 0 && (
        <Table.Row key={info.solutionId}>
          <Table.Cell>{info.faultTitle}</Table.Cell>
          <Table.Cell className={colorName(info.solutionStatus)}>
            <Label horizontal>{info.solutionStatus.toUpperCase()}</Label>
            {deleteIconStatus(info)}
            {editIconStatus(info)}
          </Table.Cell>
        </Table.Row>
      )
    );
  });

  const handleStatusChange = (event, data) => {
    let statusKey = "";
    if (data.value === statusOptions[0].value) {
      statusKey = statusOptions[0].key;
    }
    if (data.value === statusOptions[1].value) {
      statusKey = statusOptions[1].key;
    }
    if (data.value === statusOptions[2].value) {
      statusKey = statusOptions[2].key;
    }
    let filteredArray = solutions.filter(function (el) {
      return el.solutionStatus === statusKey;
    });
    setSolutionsByStatus(filteredArray);
  };

  const statusOptions = [
    {
      key: "SAVED",
      text: "SAVED",
      value: "inprogress",
    },
    {
      key: "IN REVIEW",
      text: "IN REVIEW",
      value: "inreview",
    },
    {
      key: "APPROVED",
      text: "APPROVED",
      value: "approved",
    },
  ];

  const onFilter = (key) => {
    var filterdata = solutions
      .filter((x) => x.faultTitle.toLowerCase().includes(key.toLowerCase()))
      .filter((x) => x !== undefined);
    setSolutionsByStatus(filterdata === [] ? solutions : filterdata);
  };

  return (
    <div className="session-summary">
      <div className="main-header">
        <h2>New solutions</h2>
      </div>
      <div>
        <Form>
          <Grid padded>
            <Grid.Row stretched>
              <Grid.Column className="searchsolution" width={10}>
                <Form.Field>
                  <Input
                    icon="search"
                    iconPosition="left"
                    placeholder="Search"
                    onChange={(e) => {
                      onFilter(e.target.value);
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column className="searchsolutionstatus" width={6}>
                <Dropdown
                  selection
                  options={statusOptions}
                  placeholder="Status"
                  onChange={handleStatusChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
      <Table celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Solutions</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{displayData}</Table.Body>
      </Table>
      <Menu borderless fixed="bottom" id="menu">
        <Menu.Item className="fullwidthmenu">
          <Button
            type="submit"
            className="primarybtn"
            onClick={getAddFaultDetails}
          >
            Add a new solution
          </Button>
        </Menu.Item>
      </Menu>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isSolutionNotSaved: state.isSolutionNotSaved,
    solutionId: state.solution ? state.solution.solutionId : "",
  };
};

export default connect(mapStateToProps, {
  resetSolutionFault,
  resetSteps,
  addSolution,
  addSteps,
  addStepCollection,
  solutionNotSaved,
})(NewSolutions);
