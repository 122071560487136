import React, { useEffect, useRef, useState } from "react";
import { Accordion, Icon, Popup, Menu } from "semantic-ui-react";
import moveicon from "../../assets/images/Shape.png";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes.js";
import videoImg from "../../assets/images/video.png";
import photoImg from "../../assets/images/photos.png";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  removeStep,
  addSteps,
  deleteStepsCollections,
  editStepDetails,
  saveEditedStepIds,
} from "../../redux/actions";
import { useHistory } from "react-router-dom";

const Card = ({
  id,
  title,
  description,
  tools,
  spares,
  additionalInfo,
  documents,
  index,
  moveCard,
  media,
  stepId,
}) => {
  const style = {};
  const [activeIndex, setActiveIndex] = useState(0);
  const [showImages, setShowImages] = useState(false);
  const [showVideos, setShowVideos] = useState(false);
  const steps = useSelector((state) => state.steps);
  const dispatch = useDispatch();
  let history = useHistory();
  const onClickSection = (e, props) => {
    let sectionIndex = props.index;
    let newActiveIndex = activeIndex === sectionIndex ? -1 : sectionIndex;
    setActiveIndex(newActiveIndex);
  };

  useEffect(() => {
    if (stepId.length > 0 && media) {
      media.forEach((file) => {
        if (file.fileName) {
          const ext = file.fileName.split(".").pop();
          file.extention = ext;
        }
      });
    }
  }, [media, stepId]);

  // Drag and Drop functionality
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));

  // handle delete step
  const handleDelete = (e) => {
    e.preventDefault();
    const selectedStep = steps.filter((step) => step.title === title);
    if (selectedStep[0].stepId.length > 0) {
      const stepId = {
        stepId: selectedStep[0].stepId,
      };
      dispatch(deleteStepsCollections(stepId));
      dispatch(removeStep(selectedStep[0].title));
    } else {
      dispatch(removeStep(selectedStep[0].title));
    }
  };

  //handle Edit step
  const handleEditStep = () => {
    const selectedStep = steps.filter((step) => step.title === title);
    dispatch(editStepDetails(selectedStep[0]));
    dispatch(saveEditedStepIds(selectedStep[0].stepId));
    history.push(`/newstep/`);
  };

  // Render the images aganist the selected step
  const renderDownloadedImages = () => {
    var displayImages = [];
    if (stepId.length > 0 && media?.length > 0) {
      for (let i = 0; i < media.length; i++) {
        if (
          media[i].extention === "png" ||
          media[i].extention === "PNG" ||
          media[i].extention === "jpeg" ||
          media[i].extention === "jpg"
        ) {
          displayImages.push(
            <>
              <img className="image-display" src={media[i].url} alt="" />
            </>
          );
        }
      }
    }
    return displayImages;
  };

  // Render the videos aganist the selected step
  const renderDownloadedVideos = () => {
    var displayVideos = [];
    if (stepId.length > 0) {
      // Render files downloaded
      for (let i = 0; i < media.length; i++) {
        if (media[i].extention === "mp4" ||
            media[i].extention === "MOV") {
          displayVideos.push(
            <video className="video-display" controls>
              <source
                className="video-display"
                src={media[i].url}
                alt=""
                type="video/mp4"
              />
            </video>
          );
        }
      }
    }
    return displayVideos;
  };

  return (
    <>
      <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
        <Accordion fluid styled>
          <Accordion.Title active={activeIndex === 1}>
            <img src={moveicon} alt="" />
            {title}
            <Icon name="chevron down" index={1} onClick={onClickSection} />
            <Popup
              content={
                <>
                  <Menu vertical>
                    <Menu.Item name="editstep" onClick={() => handleEditStep()}>
                      Edit step
                    </Menu.Item>
                    <Menu.Item
                      name="deletestep"
                      onClick={(e) => handleDelete(e)}
                    >
                      Delete step
                    </Menu.Item>
                  </Menu>
                </>
              }
              on="click"
              id="lexxPopUpMenu"
              position="left center"
              trigger={<i className="ellipsis vertical icon" id="penIcon"></i>}
            />
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <h4 style={{ fontSize: "14px" }}>Troubleshooting</h4>
            <p style={{ fontSize: "14px" }}>{description}</p>
            <h4 style={{ fontSize: "14px" }}>Tools required</h4>
            <p style={{ fontSize: "14px" }}>{tools}</p>
            <h4 style={{ fontSize: "14px" }}>Necessary spares</h4>
            <p style={{ fontSize: "14px" }}>{spares}</p>
            <h4 style={{ fontSize: "14px" }}>Additional Information</h4>
            <p style={{ fontSize: "14px" }}>{additionalInfo}</p>

            {documents &&  <>
              <h4 style={{ fontSize: "14px" }}>Documents referenced</h4>
              <p className="paragraph-bg">{documents}</p>
              </>}

            <h4 style={{ fontSize: "14px" }}>Step attachments</h4>
            <ul>
              <li>
                <div>
                  <img src={photoImg} alt="" />
                  <span className="record">Photos</span>
                  <span className="arrow">
                    <button onClick={() => setShowImages(!showImages)}>
                      {!showImages ? <>&gt;</> : "v"}
                    </button>
                  </span>
                </div>
                {showImages &&
                  media?.length > 0 && (
                    <li>{renderDownloadedImages()}</li>
                  )}
              </li>
              <li>
                <div>
                  <img src={videoImg} alt="" />
                  <span className="record">Videos</span>
                  <span className="arrow">
                    <button onClick={() => setShowVideos(!showVideos)}>
                      {!showVideos ? <>&gt;</> : "v"}
                    </button>
                  </span>
                </div>
                {showVideos && media?.length > 0  && (
                    <li>{renderDownloadedVideos()}</li>
                  )}
              </li>
            </ul>
          </Accordion.Content>
        </Accordion>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    steps: state.steps,
  };
};

export default connect(mapStateToProps, {
  addSteps,
  removeStep,
  deleteStepsCollections,
  editStepDetails,
})(Card);
