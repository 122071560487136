import React, { useState, useEffect } from 'react';
import { Image, Input, Icon, List } from 'semantic-ui-react';
import availableIcon from '../../assets/images/available.png';
import busyIcon from '../../assets/images/busy.png';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getOrganizationWideUsers } from '../apiCall';
import userDummy from '../../assets/images/user-dummy.png';

/* This is the component to show the team list - for a user  */
function LexxTeamResult(props) {

    const [searchValue, setSearchValue] = useState(""); // search value for team members
    const [teamList, setTeamList] = useState([]); // list of team members which changes when user searches
    const [stableTeam, setStableTeam] = useState([]); // stable list which will contain all team members in the list

    /* function called when component is mounted 
        get the team list from server
        also set the variable with the data for search and display
    */
    const getTeamList = () => {
        getOrganizationWideUsers().then((response) => {
            setStableTeam(response.data.data.users.length === 0 ? [] : response.data.data.users); // set to stable list for search
            setTeamList(response.data.data.users.length === 0 ? [] : response.data.data.users); // set to organization list which is reduced to when searched
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(getTeamList, []);

    /* When the user searches for a team member 
        filter from the list and check if the value entered matches name or title
        of team member
        @event - event is the variable returned from UI
     */ 
    const searchTeam = (event) => {
        setSearchValue(event.currentTarget.value);
        const filteredData = stableTeam.filter(item => { // filter from list
            return Object.keys(item).some((eachKey) => { // go through the object of each team member
                if (eachKey === "name" || eachKey === "title") {
                    return item[eachKey].toLowerCase().includes(event.currentTarget.value.toLowerCase());
                } else {
                    return null;
                }
            })
        });
        setTeamList(filteredData); // set with the filtered data
    }

    /* Redirect user to other technician profile */
    const showTeamMemberProfile = (userId) => {
        props.history.push(`/userprofile/id=${userId}`);
    }

    return (
        <div className="teamContainer">
            <div className="teamHeader">
                <div className="left-float">
                    <h2>
                        My Team
                    </h2>
                </div>
            </div>
            <div className="searchTeam">
                <Input
                    icon={<Icon name='search' color="black" />}
                    iconPosition='left' placeholder='Search' value={searchValue} onChange={searchTeam} />
            </div>
            <div className="teamList">
                <List>
                    {teamList.length > 0 ?
                        teamList.map((tech, i) => {
                            return (
                                <List.Item key={i} data-testid={`team-item-${i}`}>
                                    {
                                        tech.profileImg !== "" ?
                                            <Image avatar src={tech.profileImg} onClick={() => showTeamMemberProfile(tech.userId)} /> :
                                            <Image avatar src={userDummy} onClick={() => showTeamMemberProfile(tech.userId)} />
                                    }
                                    {tech.status === "available" ?
                                        <Image className="status" src={availableIcon} /> :
                                        <Image className="status" src={busyIcon} />
                                    }
                                    <List.Content>
                                        <List.Header>{tech.name}</List.Header>
                                        <List.Description>
                                            {tech.title}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            )
                        }) : null
                    }
                </List>

            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        userId: state.userInfo.userId
    };
};

export default withRouter(connect(mapStateToProps)(LexxTeamResult));