import * as React from "react";

const List = (props) => (
    <>
        {(props.table && props.table.length > 0) && (
            <div className="procedure-table" dangerouslySetInnerHTML={{__html: props.table}}></div>
        )}
    </>
);

export default List;
