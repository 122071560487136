import React, { Component } from 'react'
import { Image, Menu } from 'semantic-ui-react';
import { withRouter } from "react-router-dom";
import homeImg from '../../assets/images/navbar/home.svg';
import homeSelImg from '../../assets/images/navbar/home-selected.svg';
import notificationImg from '../../assets/images/navbar/notifications.svg';
import notificationSelImg from '../../assets/images/navbar/notifications-selected.svg';
import performanceImg from '../../assets/images/navbar/performance.svg';
import performanceSelImg from '../../assets/images/navbar/performance-selected.svg';
import reportImg from '../../assets/images/navbar/report.svg';
import reportSelImg from '../../assets/images/navbar/report-selected.svg';
import tasksImg from '../../assets/images/navbar/tasks.svg';
import tasksSelImg from '../../assets/images/navbar/tasks-selected.svg';
import toolsImg from '../../assets/images/navbar/tools.svg';
import toolsSelImg from '../../assets/images/navbar/tools-selected.svg';


/* Component is for bottom menu bar for Dashboard 
 1. Dashboard - active
 2. Tasks - active
 3. Tools - not active
 4. Performance - not active
 5. Reports - not active
 6. Notifications  - active
 */
class LexxFooterToolbar extends Component {
    state = { activeItem: 'Dashboard' }

    /* Function when any menu item is clicked and redirect the user to that page */
    handleItemClick = (e, { name }) => {
        this.setState({ activeItem: name });
        switch(name) {
            // case 'Tasks':
            //     this.props.history.push("/allTasks");
            //     break;
            default:
                this.props.history.push("/");
                break;
        }
    }

    render() {
        const { activeItem } = this.state //to check which item is active so css can be applied

        const items = [
            {
                text: 'Dashboard',
                icon: homeImg,
                iconSelected: homeSelImg
            },
            {
                text: 'Tasks',
                icon: tasksImg,
                iconSelected: tasksSelImg
            },
            {
                text: 'Tools',
                icon: toolsImg,
                iconSelected: toolsSelImg
            },
            {
                text: 'Performance',
                icon: performanceImg,
                iconSelected: performanceSelImg
            },
            {
                text: 'Reports',
                icon: reportImg,
                iconSelected: reportSelImg
            },
            {
                text: 'Notifications',
                icon: notificationImg,
                iconSelected: notificationSelImg
            }
        ]

        return (
            <Menu className="menu-height">
                {
                    items.map((val, i) => {
                        return (<Menu.Item
                            key={i}
                            name={val.text}
                            active={activeItem === val.text}
                            onClick={this.handleItemClick}
                            className="footer-menu"
                        >
                            {
                                activeItem === val.text ?
                                    <>
                                        <Image src={val.iconSelected} className='footer-toolbar-image' />
                                        <p className='footer-toolbar-text-selected'>{val.text}</p>
                                    </> :
                                    <>
                                        <Image src={val.icon} className='footer-toolbar-image' />
                                        <p className='footer-toolbar-text'>{val.text}</p>
                                    </>
                            }

                        </Menu.Item>)
                    })
                }
            </Menu>
        )
    }
}


export default withRouter(LexxFooterToolbar);