import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import SavedSessionCard from './SavedSessionCard';
import { getAllSessions } from '../apiCall';
// import CarouselCardTask from "./CarouselCardTask";


/* This component as the name suggests is for Carousel in the dashboard
    The carousel will be displayed on the session and Tasks */
const LexxCarousel = (props) => {
    const [sessions, setSessions] = useState("");
    // const [tasks, setTasks] = useState("");

    /* This functions gets all the sessions from the db */
    const getSessions = () => {
        getAllSessions().then((response) => {
            setSessions(response.data.data);
        })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        getSessions(); 
    }, []);


    /* This return statement will display the session and tasks in carousel 
        by calling respective components SavedSessionCard and CarouselCardTask
    */
    return (
        <>
            {
                sessions.length !== 0 ?
                    <div className="sessions-wrapper">
                        <div className="carousel-header">
                            <p className='section-header-text left-float'>My Latest Sessions</p>
                            <p className='section-header-text right-float blue-color' onClick={() => props.history.push("/allSessions")}>All My sessions</p>
                        </div>
                        <div className="sliding-cards">
                            {   
                            // loop over the sessions
                                sessions.map((session, idx) => {
                                    return <SavedSessionCard key={idx} sessionData={session} isClickable={true} />
                                }).reverse() // latest session displayed first
                            }
                        </div>
                    </div>
                    :
                    null
            }
            {
                // tasks.length !== 0 ?
                    <div className="tasks-wrapper">
                        <div className="carousel-header">
                            <p className='section-header-text left-float'>My Latest Tasks</p>
                            <p className='section-header-text right-float blue-color disable-color' onClick={null}>All Tasks</p>
                        </div>
                        <div className="sliding-cards">
                            {/* {
                                // loop over the tasks
                                tasks.map((val, idx) => {
                                    return <CarouselCardTask key={idx} val={val} isClickable={true} />;
                                })
                            } */}
                        </div>
                    </div>
                    // :
                    // null
            }
        </>
    )

}

const mapStateToProps = (state) => {
    return {
        savedSessions: state.savedSessions
    };
};

export default withRouter(connect(mapStateToProps)(LexxCarousel));