import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { logData } from "../apiCall";
import KeycloakUserService from '../login/KeyCloakService';


/** This component is for the three dots on the Header */
const ContextMenu = (props) => {

    /* When the user clicks on logout */
    const logout = () => {
        const data = { params: { username: props.userInfo.username, sessionTime: props.timer / 1000 }}; //logout request sent to the backend
        logData(data);
        KeycloakUserService.doLogout();
    }

    return (
        <Dropdown
            icon='ellipsis vertical'
            floating
            labeled
            button
        >
            <Dropdown.Menu direction='left'>
                <Dropdown.Item icon='log out' text='Logout' onClick={() => {logout(); }} />
            </Dropdown.Menu>
        </Dropdown>
    );
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo
    };
}

export default connect(mapStateToProps)(ContextMenu);