import axios from "axios";

const SIGNUP = "/users";
const ALL_SESSIONS = "/worksessions";
const START_UPDATE_SESSION = "/worksessions";
const GET_TEAM_SESSION = "/worksessions/";

const SEARCH = "/search";
const GET_PROCEDURE = "/procedure";

const GET_PROFILE = "/users/";
const GET_PROFILE_LINK = "/users/avatars/uplink/";
const PROFILE_UPLOADED = "users/avatars/key";
const CHANGE_STATUS = "/users/status";
const SAVE_PROFILE = "/users/profile";
const CALL_MADE = "/comms";

const GET_ALL_USERS = "/users";

const GET_TEAM_LIST = '/teams';

const GET_NOTIFICATIONS = "/notifications";

const POST_FEEDBACK = "/updateActivities";
const POST_STEP_FEEDBACK = "/updateStepActivities";
const LOG_DATA = "/dummy";

const FIREBASE_CALL = "/users/firebaseToken";
const GET_SOLUTIONS = "/solutions";
const SAVE_SOLUTIONS = "/solutions";
const SAVE_NEW_STEP = "/steps";
const SOLUTION_ACTIONS = "/solutions/";
const GET_STEPS = "/steps";
const STEP_ACTIONS = "/steps/";


export function authSignup(data){
    return axios.post(SIGNUP, data);
}

export function getAllSessions() {
  return axios.get(ALL_SESSIONS);
}

export function getSessionByTaskId(url) {
    return axios.get(ALL_SESSIONS + url);
}

export function getSingleSession(sessionID){
    return axios.get(ALL_SESSIONS + "/" + sessionID);
}

export function getTeamSession(url){
    return axios.get(GET_TEAM_SESSION + url + "/team");
}

export function faultFixed(url){
    return axios.post(GET_TEAM_SESSION + url + "/completeTime");
}

export function search(query, isTroubleshooting) {
  return axios.post(SEARCH, { q: query, isTroubleshooting: isTroubleshooting});
}

export function getProcedure(data){
    return axios.get(GET_PROCEDURE, data);
}

export function getSearchResultData(url, data){
    return axios.get(url, data);
}

export function getProfile (requesterID) {
    return axios.get(GET_PROFILE + requesterID);
}

export function getProfileImageLink (requesterID, fileName) {
    return axios.get(GET_PROFILE_LINK + requesterID + fileName);
}

export function uploadProfileImage (link, file) {
    let options = { headers: { 'Content-Type': 'image/*' } };
    return axios.put(link, file, options);
}

export function profileUploaded (data) {
    return axios.post(PROFILE_UPLOADED, data);
}

export function callFunctionalityUsed(data) {
    return axios.post(CALL_MADE, data);
}

export function changeStatus(status){
    return axios.post(CHANGE_STATUS, {status: status});
}

export function getUserProfile(userToBeRequested, requesterID){
    return axios.get(GET_PROFILE + userToBeRequested, { params: {requesterId: requesterID }});
}

export function saveProfile(data){
    return axios.post(SAVE_PROFILE, data);
}

export function getNotifications(){
    return axios.get(GET_NOTIFICATIONS);
}

export function markNotificationRead(url){
    return axios.post(GET_NOTIFICATIONS + url);
}

export function newSession(sessionData){
    return axios.post(START_UPDATE_SESSION, sessionData);
}

export function updateSession(url, sessionData){
    return axios.post(START_UPDATE_SESSION + url, sessionData);
}

export function getTeam(){
    return axios.get(GET_TEAM_LIST); 
}

export function deleteTeamMember(url){
    return axios.delete(GET_TEAM_LIST + url);
}

export function getOrganizationWideUsers(){
    return axios.get(GET_ALL_USERS);
}

export function logData(data){
    return axios.post(LOG_DATA, data);
}

export function postFeedback(data){
    return axios.get(POST_FEEDBACK, data);
}

export function postStepFeedback(data){
    return axios.post(POST_STEP_FEEDBACK, data);
}

export function sendFirebaseToken(data){
    return axios.post(FIREBASE_CALL, data);
}

export function getAllSolutions(){
    return axios.get(GET_SOLUTIONS);
}

export function saveSolution(data){
    return axios.put(SAVE_SOLUTIONS,data);
}

export function saveNewStep(data, solutionId){
    return axios.put(SOLUTION_ACTIONS + solutionId + SAVE_NEW_STEP, data);
}

export function deleteSolution(id){
    return axios.delete( SOLUTION_ACTIONS +id);
}

export function getSolutionById(id) {
    return axios.get(GET_SOLUTIONS+"/"+id)
}

export function getStepBySolutionId(solutionId) {
    return axios.get(SOLUTION_ACTIONS+solutionId+GET_STEPS);
}

export function updateSolution(solutionData){
    return axios.patch(SAVE_SOLUTIONS, solutionData);
}

export function deleteSteps(solutionId, stepId) {
    return axios.delete(SOLUTION_ACTIONS + solutionId + STEP_ACTIONS + stepId);
}

export function saveEditedStep (solutionId, stepDetails, stepId) {
    return axios.patch(SOLUTION_ACTIONS+solutionId+STEP_ACTIONS+stepId, stepDetails);
}