import * as React from "react";

const Para = (props) => (
    <>
        <div>
            {props.text}
        </div>
        {(props.children && props.children.length > 0) && props.children}
    </>
);

export default Para;
