import * as React from "react";
import { Grid, Header, Segment } from "semantic-ui-react";

const Title = (props) => (
    <Grid.Row>
        <Segment basic>
            <Header as="h2" dividing>
                {/*
                    We use this special 'offset-anchor' element because it has an offset from
                    the top of the page. This means when you navigate to the anchor, the actual
                    anchor starting point isn't hidden behind the fixed header
                */}
                <a className="offset-anchor" id={props.anchor} href={props.anchor}>
                {props.text}
                </a>
            </Header>
            {(props.children && props.children.length > 0) && props.children}
        </Segment>
    </Grid.Row>
);

export default Title;
