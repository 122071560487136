import React, { useEffect, useState } from "react";
import AllSteps from "./Container";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Breadcrumb, Button, Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { deleteSteps, saveEditedStep } from "../apiCall";
import {
  addSteps,
  addStepCollection,
  addSolution,
  deleteStepsCollections,
  updateStepId,
  updateStep,
  resetEditStepIds,
  solutionNotSaved,
} from "../../redux/actions";

const AddSolutionSteps = (props) => {
  const sections = [
    { key: "Fault title", content: "Fault title", link: true },
    { key: "Add steps", content: "Add steps", active: true },
  ];

  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [showPendingAlert, setShowPendingAlert] = useState(false);
  const [solutionDescription, setSolutionDescription] = useState();
  const [buttonEnbale, setButtonEnable] = useState(false);

  useEffect(() => {
    const characterLimit = isMobile ? 120 : 550;
    if (props.description.length > characterLimit) {
      setSolutionDescription(
        props.description.slice(0, characterLimit) + "..."
      );
    } else {
      setSolutionDescription(props.description);
    }
    sortSteps(props.steps);
  }, [props.description, props.steps]);

  const sortSteps = (steps) => {
    steps.sort(function (a, b) {
      return a.order_id - b.order_id;
    });
  };
  const saveSolutions = async () => {
    //Save steps aganist existing solution
    await saveStepsBySolution();

    // Delete Steps
    await props.deleteStepsCollection.forEach((step) => {
      deleteSteps(props.solutionId, step.stepId);
    });
  };

  // Save steps by solution id
  const saveStepsBySolution = async () => {
    sortSteps(props.steps);
    const editedStepIdCollection = props.editStepIds;
    props.resetEditStepIds(true);
    const editedStepIds = getUniqueIds(editedStepIdCollection);
    for (let i = 0; i < props.steps.length; i++) {
      const step = props.steps[i];
      for (let i = 0; i < editedStepIds.length; i++) {
        const stepId = editedStepIds[i];
        if (step.stepId === stepId) {
          const formData = createFormData(step, true);
          await saveEditedStep(props.solutionId, formData, stepId);
        }
      }
    }
  };

  // Get unique steps Ids
  const getUniqueIds = (editStepIds) => {
    return editStepIds.filter(
      (item, index) => editStepIds.indexOf(item) === index
    );
  };
  // Create formdata to save step
  const createFormData = (step, isEditstep = false) => {
    const formData = new FormData();
    let stepDetail;
    stepDetail = {
      title: step.title,
      description: step.description,
      tools: step.tools,
      spares: step.spares,
      additionalInfo: step.additionalInfo,
      documents: step.documents,
      order_id: step.order_id,
      _id: step.stepId,
      mediaToBeDeleted: step.deleteFileCollection,
    };
    formData.append("data", JSON.stringify(stepDetail));
    return formData;
  };

  const handleButtonClick = async () => {
    props.solutionNotSaved(false);
    setButtonEnable(true);
    setShowPendingAlert(true);
    await saveSolutions();
    setShowPendingAlert(false);
    setShowSaveAlert(true);
    // setting to true to display alert and redirect to New Solutions
    setTimeout(() => {
      props.history.push(`/newsolutions/`);
    }, 2000);
  };

  // handle submit button click
  const handleSubmitButtonClick = async () => {
    props.solutionNotSaved(false);
    setButtonEnable(true);
    await saveSolutions();
    // setting to true to display alert and redirect to New Solutions
    setTimeout(() => {
      props.history.push(`/solutionsummary`);
    }, 1000);
  };

  let submitAlertClass = showSaveAlert ? "modal-alert animate" : "modal-alert";
  let submitAlertStyles =
    showSaveAlert || showPendingAlert
      ? {}
      : { opacity: 0, visibility: "hidden", pointerEvents: "none" };

  const getAddStep = () => {
    props.history.push(`/newstep/`);
  };

  return (
    <div>
      {showSaveAlert && (
        <div
          className={submitAlertClass}
          style={submitAlertStyles}
          variant={"success"}
        >
          <div className="modal-alert-icon">
            <img
              className="PopUpIcon"
              src={require("../../assets/images/Shape 1.png")}
              alt=""
            />
          </div>
          <div className="model-alert-msg">
            <h4 className="Heading">Solution saved</h4>
            <p className="Para">You can edit it again later</p>
          </div>
        </div>
      )}
      {showPendingAlert && (
        <div
          className={submitAlertClass}
          style={submitAlertStyles}
          variant={"warning"}
        >
          <div className="modal-alert-icon">
            <img
              className="PopUpIcon"
              src={require("../../assets/images/pending-shape.png")}
              alt=""
            />
          </div>
          <div className="model-alert-msg">
            <h4 className="Heading">Saving in progress</h4>
            <p className="Para">Please wait while saving is completed</p>
          </div>
        </div>
      )}
      <div className="session-summary">
        <div className="main-heading">
          <h2>Add a solution</h2>
        </div>
        <Breadcrumb icon="right angle" sections={sections} />
        <div className="fault-details">
          <i
            className="pencil icon"
            id="faultDetailsPenIcon"
            onClick={() => props.history.push(`/addsolutionsfaultdetails/`)}
          ></i>
          <h3>{props.title}</h3>
          <h6>{props.code}</h6>
          <p>{solutionDescription}</p>
        </div>
        <div className="breadcrumb-sub-heading">
          <h3> Add/Edit Troubleshooting Steps</h3>
        </div>

        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
          {props.steps.length > 0 && <AllSteps steps={props.steps} />}
        </DndProvider>
        <Button className="stepbtn" onClick={getAddStep}>
          + Add a step
        </Button>
        <Menu borderless fixed="bottom" id="three-menu">
          <Menu.Item>
            <Button
              className="secondarybtn"
              onClick={() => props.history.goBack()}
            >
              Back
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button 
              className="secondarybtn" 
              onClick={handleButtonClick}
              disabled={buttonEnbale}
              >
              Save
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button
              type="submit"
              className="primarybtn"
              disabled={buttonEnbale}
              onClick={handleSubmitButtonClick}
            >
              Submit
            </Button>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    title: state.solution.title,
    code: state.solution.code,
    type: state.solution.type,
    description: state.solution.description,
    solutionId: state.solution.solutionId,
    steps: state.steps,
    stepsCollection: state.stepsCollection,
    deleteStepsCollection: state.deleteStepsCollection,
    editStepIds: state.editStepIds,
  };
};

export default connect(mapStateToProps, {
  addSteps,
  addStepCollection,
  addSolution,
  deleteStepsCollections,
  updateStepId,
  updateStep,
  resetEditStepIds,
  solutionNotSaved,
})(AddSolutionSteps);
