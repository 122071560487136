import React, { useState } from 'react';
import { Container } from 'semantic-ui-react';
import axios from 'axios';
import { connect } from 'react-redux';
import { saveSearchResults, saveTroubleshootingOption, saveVisitedTroubleshootingSteps, saveSearchQuery, searchClicked, saveTroubleshootingData } from '../../redux/actions';
import LexxSearchBar from '../LexxSearchBar/lexxsearchbar.jsx';
import LexxBreadcrumbs from '../LexxBreadcrumbs/LexxBreadcrumbs';
import LexxIconHeading from '../global/LexxIconHeading';
import Loader from '../global/Loader';
import { search } from '../apiCall';

/* This is the SEARCH page where users search for fault code or WI */
const TroubleshootingHome = (props) => {

    const [isloading, setIsLoading] = useState(false); // to show loader when the user searches 

    /* Function called when the user searches for anything 
     @query - input from the user 
     */
    const triggerSearch = (query) => {
        setIsLoading(true); 
        axios.defaults.params = {}
        axios.defaults.params['username'] =  props.userInfo.username;
        search(query, props.isTroubleshooting).then((res) => { //sent to the backend
            // Save and reset redux
            props.searchClicked(true);
            props.saveSearchResults(res.data.docs);
            props.saveTroubleshootingOption(null);
            props.saveTroubleshootingData(null);
            props.saveVisitedTroubleshootingSteps([]);
            props.saveSearchQuery(query);
            setIsLoading(false);
            props.history.push('/troubleshooting/issues');
        }).catch((err) => {
            setIsLoading(false);
            console.error(err);
        });
    };

    if (isloading) {
        return (
            <Loader />
        )
    } else {
        return (
            <div className='content-container'>
                <LexxBreadcrumbs />
                <Container>
                    {props.isTroubleshooting ?
                        <LexxIconHeading text="Can you please describe the fault?" /> :
                        <LexxIconHeading text="Can you please describe what information you want to look up?" />
                    }

                    <div className="marginL3">
                        <p className='section-header-text'>Your response</p>
                        <LexxSearchBar
                            className='search-input-box'
                            act={(query) => { triggerSearch(query) }}
                        />
                        {props.isTroubleshooting ? null :
                            <p>You can search for information within past work orders, work instructions or schematics.</p>
                        }
                    </div>
                </Container>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        searchResults: state.searchResults, //search results from the redux
        isTroubleshooting: state.isTroubleshooting, //if it is troubleshooting or not
        userInfo: state.userInfo //user data
    };
};

export default connect(mapStateToProps, {
    saveSearchResults,
    saveTroubleshootingOption,
    saveVisitedTroubleshootingSteps,
    saveSearchQuery,
    searchClicked,
    saveTroubleshootingData
})(TroubleshootingHome);