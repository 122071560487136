import update from "immutability-helper";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Card from "./Card.jsx";
import { updateStep, saveEditedStepIds } from "../../redux/actions";

export const Container = (steps) => {
  const [cards, setCards] = useState(steps.steps);
  const [isCardMoved, setIsCardMoved] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setCards(steps.steps);
  }, [steps.steps]);

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setIsCardMoved(true);
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  // Render Each steps
  const renderCard = (card, index) => {
    if (card) {
      if (isCardMoved) {
        const cardDetails = {
          title: card.title,
          description: card.description,
          tools: card.tools,
          spares: card.spares,
          additionalInfo: card.additionalInfo,
          documents:card.documents,
          order_id: index,
          stepId: card.stepId,
          media: card.media,
        };
        dispatch(updateStep(cardDetails));
        if (card.stepId) {
          dispatch(saveEditedStepIds(card.stepId));
        }
      }
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          title={card.title}
          description={card.description}
          tools={card.tools}
          spares={card.spares}
          additionalInfo={card.additionalInfo}
          documents={card.documents}
          moveCard={moveCard}
          media={card.media}
          stepId={card.stepId ? card.stepId : ""}
        />
      );
    }
  };

  return (
    <>
      <div>{cards?.map((card, i) => renderCard(card, i))}</div>
    </>
  );
};

export default Container;
